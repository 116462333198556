import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationCancel,
  NavigationEnd,
  Router,
} from '@angular/router';
import { filter } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { LobbyItem } from '@scpc/dto/lobby';
import { LayoutComponent } from '@scpc/modules/common/components/layout/layout.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BreadcrumbsComponent } from '@scpc/modules/common/components/breadcrumbs/breadcrumbs.component';
import { SearchComponent } from '@scpc/modules/sports/components/search/search.component';
import { BreadcrumbItem } from '@scpc/modules/common/components/breadcrumbs/breadcrumbs';

@Component({
  selector: 'scp-draws-lobby',
  templateUrl: './draws-lobby.component.html',
  styleUrls: ['./draws-lobby.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LayoutComponent,
    BreadcrumbsComponent,
    SearchComponent,
  ],
})
export class DrawsLobbyComponent implements OnInit {

  protected lobby: LobbyItem[];
  protected breadcrumbs: BreadcrumbItem[];
  protected loadingBreadcrumbs: boolean = true;
  protected product: string;
  protected search: boolean;
  protected isBrowser: boolean = isPlatformBrowser(inject(PLATFORM_ID));

  constructor(private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              private readonly changeDetectorRef: ChangeDetectorRef) {
    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter(/* istanbul ignore next */(event: NavigationEnd | NavigationCancel) => event instanceof NavigationEnd || event instanceof NavigationCancel),
      )
      .subscribe(/* istanbul ignore next */ async () => this.updateComponentData());

    this.activatedRoute.queryParams
      .pipe(
        takeUntilDestroyed(),
      )
      .subscribe(/* istanbul ignore next */ async () => this.updateBreadcrumbs());
  }

  public ngOnInit(): void {
    this.updateComponentData();
  }

  private getSnapshot(): ActivatedRouteSnapshot {
    const activatedRoute: ActivatedRoute = (this.activatedRoute.firstChild.firstChild || this.activatedRoute.firstChild);
    return activatedRoute.snapshot;
  }

  public updateComponentData(): void {
    const snapshot: ActivatedRouteSnapshot = this.getSnapshot();
    this.lobby = snapshot.data.lobby?.filter(i => i.type !== 'SEARCH_ITEM');
    this.product = snapshot.data.product;
    this.search = snapshot.data.search && !this.lobby;
    this.changeDetectorRef.markForCheck();
    this.loadingBreadcrumbs = false;
    this.updateBreadcrumbs();
  }

  private updateBreadcrumbs(): void {
    const snapshot: ActivatedRouteSnapshot = this.getSnapshot();

    if (!snapshot) {
      return;
    }

    this.breadcrumbs = [
      {
        name: '',
        icon: '/assets/common/top.svg',
        href: '/',
      },
      {
        name: 'Lucky Numbers',
        href: '/lucky-numbers',
      },
    ];
    const type = snapshot.data.type;
    const market = snapshot.queryParams.market;
    const title = snapshot.data.title;
    const draw = snapshot.data.draw?.draw;
    const result = snapshot.data.result?.result;
    const results = snapshot.data.results?.results;

    if (type === 'INSTANT') {
      this.breadcrumbs.push({ name: 'Rapid', href: '/lucky-numbers/rapid' });
    }

    if (type === 'POPULAR') {
      this.breadcrumbs.push({ name: 'Popular', href: '/lucky-numbers/popular' });
    }

    if (snapshot.url.toString().includes('weekly-draws-schedule')) {
      this.breadcrumbs.push({ name: 'Weekly draws schedule', href: '/lucky-numbers/weekly-draws-schedule' });
    }

    if (title) {
      this.breadcrumbs.push({ name: title });
    }

    if (draw) {
      this.breadcrumbs.push({ name: draw.drawName, href: '/lucky-numbers/' + draw.drawId });
    }

    if (market && this.router.url.split('?')[0].endsWith('/selections')) {
      this.breadcrumbs.push({ name: market });
    }

    if (result || results) {
      this.breadcrumbs.push({ name: 'Results' });
    }
  }

}
